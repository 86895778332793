import { useEffect, useState } from 'react';
import { api_url_base } from '../types/constants';
import { Tab, Table, Tabs } from 'react-bootstrap';
import './HotelFinderView.css';

export function HotelFinderView() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cityName, setCityName] = useState<string>('Vienna')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [countryIso3, setCountryIso3] = useState<string>('AUT')
  const [hotels, setHotels] = useState<any[] | undefined>()
  const [hotelSelected, setHotelSelected] = useState<any | undefined>()
  const [hotelToPoi, setHotelToPoi] = useState<any[] | undefined>()

  useEffect(() => {
    fetch(`${api_url_base}v1/hotels/cityName=${cityName}&countryIso3=${countryIso3}`)
      .then(response => {
        if (response.ok) {
          response.json()
            .then(body => {
              setHotels((body as any[]).sort((hotelA, hotelB) => hotelA.hotelScore < hotelB.hotelScore ? 1 : -1))
            })
        }
        else {
          console.log(`Unable to connect to API`)
        }
      })
  }, [cityName, countryIso3])

  const handleHotelRowClick = (newHotelSelected: any) => {
    if (newHotelSelected.name !== hotelSelected?.name) {
      setHotelSelected(newHotelSelected)
      setHotelToPoi(undefined)
    }
  }
  
  const hotelsTable = (hotels: any[]) => {
    return (
      <div className='side-padding-10vw padding-top column-holder'>
        <Table hover>
          <thead>
            <tr>
              <th>Hotel</th>
              <th>Score</th>
            </tr>
          </thead>
          <tbody>
            {
              hotels?.map((thisHotel, i) => {
                return (
                  <tr key={i} onClick={() => handleHotelRowClick(thisHotel)} className={`tr ${hotelSelected?.name === thisHotel.name ? 'active-tr' : ''}`}>
                    <td>{thisHotel.name}</td>
                    <td>{thisHotel.hotelScore}</td>
                  </tr>
                )
              })
            }
          </tbody>
        </Table>
      </div>
    )
  }

  const getHotelToPoiData = (hotelSelected: any) => {
    // GET hotel to points of interet by hotel
    // TODO: Set loading status
    fetch(`${api_url_base}v1/hotelToPointOfInterest/hotel_place_id=${hotelSelected.place_id}`)
      .then(response => {
        if (response.ok) {
          response.json()
            .then(body => {
              setHotelToPoi((body as any[]).sort((htpA, htpB) => htpA.walkDistance > htpB.walkDistance ? 1 : -1))
              
              console.log(`Setting setHotelToPoi for ${hotelSelected.name}`)
            })
        }
        else {
          console.log(`Unable to connect to API`)
        }
      })
      .finally(() => {
        // TODO: Set loading complete
      })
  }

  const pointsOfInterestTable = (hotelToPoi: any[]) => {
    return (
      <div className='side-padding-10vw padding-top column-holder'>
      <Table hover>
        <thead>
          <tr>
            <th>Place</th>
            <th>Walking Distance</th>
            <th>Walking Time</th>
            <th>Transit Distance</th>
            <th>Transit Time</th>
          </tr>
        </thead>
        <tbody>
          {
            hotelToPoi?.map((thisPoi, i) => {
              return (
                <tr key={i} onClick={() => { }}>
                  <td>{thisPoi.poiName}</td>
                  <td>{thisPoi.walkDistance}</td>
                  <td>{thisPoi.walkDuration}</td>
                  <td>{thisPoi.transitDistance}</td>
                  <td>{thisPoi.transitDuration}</td>
                </tr>
              )
            })
          }
        </tbody>
      </Table>
      </div>
    )
  }

  return (
    <div className='center'>
      <h1>
        Hotels in Vienna, Austria
      </h1>
      <Tabs
        id='controlled-tab'
        defaultActiveKey='hotel'
        className='all-tabs'
        onSelect={(key) => {
          console.log(`clicked`)
          if(key === 'poi')
            getHotelToPoiData(hotelSelected)
        }}
      >
        <Tab eventKey='hotel' title='Hotel'>
          {hotels && hotelsTable(hotels)}
        </Tab>
        <Tab eventKey='poi' title='Points Of Interest' disabled={!hotelSelected}>
          {hotelToPoi && pointsOfInterestTable(hotelToPoi)}
        </Tab>
      </Tabs>
    </div>
  )
}