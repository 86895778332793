import { useEffect, useState } from 'react';
import Select from 'react-select'
import './UsefulTravelPhrases.css';
import { countryAndLanguage, languageAndPhrases, UsefulPhrases } from './languages'
import { countryLangObjects, langCode2ToLangName } from './countries'
import { Table } from 'react-bootstrap';
import { TravelProps } from '../types/TravelTypes';

export function UsefulTravelPhrases(props: TravelProps) {
  const [selectedLanguage, setSelectedLanguage] = useState<string | undefined>()
  const [selectedCountry, setSelectedCountry] = useState<string | undefined>()
  const [countryLanguageCodes, setCountryLanguageCodes] = useState<string[]>([])

  useEffect(() => {
    if (props.detectedCountryIso3) {
      setSelectedCountry(countryLangObjects[props.detectedCountryIso3].countryName)
      setSelectedLanguage(langCode2ToLangName[countryLangObjects[props.detectedCountryIso3].officialLanguageCodes[0].toUpperCase()])
      setCountryLanguageCodes(countryLangObjects[props.detectedCountryIso3].officialLanguageCodes)
    }
  },[props.detectedCountryIso3])

  const handleOptionChange = (selectedOption: any) => {
    if (selectedOption) {
      setSelectedLanguage(langCode2ToLangName[countryLangObjects[selectedOption.value].officialLanguageCodes[0].toUpperCase()])
      setSelectedCountry(selectedOption.label)
      setCountryLanguageCodes(countryLangObjects[selectedOption.value].officialLanguageCodes)
    }
  }

  const translatedPhrases = (usefulPhrases: UsefulPhrases) => {
    let phrases = []
    if (usefulPhrases) {
      for (const phrase in usefulPhrases) {
        phrases.push([phrase, (usefulPhrases as any)[phrase]])
      }
    }
    return (
      phrases.map((phrase, i) => {
        return (
          <tr key={i}>
            <td>{(languageAndPhrases['English'] as any)[phrase[0]]}</td>
            <td>{phrase[1]}</td>
          </tr>
        )
      })
    )
  }

  const languageSelector = (languageCodes2: string[]) => {
    let languageOptions: { value: string, label: string }[] = []
    languageCodes2.forEach((languageCode) => {
      const langCode = languageCode.toUpperCase()
      if (langCode2ToLangName[langCode]) {
        languageOptions.push({ value: langCode2ToLangName[langCode], label: langCode2ToLangName[langCode] })
      }
    })

    return (
      <Select
        options={languageOptions}
        defaultValue={languageOptions[0]}
        isClearable={false}
        isSearchable={false}
        onChange={(newValue) => setSelectedLanguage(newValue?.value)}
      />
    )
  }

  return (
    <div className="App">
      <div className='side-padding-10vw column-holder padding-top'>
        <div className='column vertical-center align-right padding-right'>
          Where are you traveling?
        </div>
        <div className='column'>
          <Select
            options={countryAndLanguage}
            placeholder='Country'
            onChange={handleOptionChange} />
        </div>
      </div>
      <h4 className='side-padding-10vw padding-top'>
        Language spoken in {selectedCountry}: {countryLanguageCodes.length > 1 ?
          languageSelector(countryLanguageCodes) : 
          selectedLanguage
        }
      </h4>
      <div className='side-padding-10vw padding-top column-holder'>
        <Table>
          <thead>
            <tr>
              <th>Phrase</th>
              <th>{selectedLanguage} translation</th>
            </tr>
          </thead>
          <tbody>
            { selectedLanguage && translatedPhrases(languageAndPhrases[selectedLanguage]) }
          </tbody>
        </Table>
      </div>
    </div>
  )
}
