export const api_url_base = 'http://54.173.43.71:80/'

export const openweatherApiKey = 'dbfa96b5a3e8a56e7765894d79d91ad7'

export enum ResponseStatus {
  SUCCESS,
  FAILURE
}

export interface FetchResponse {
  status: ResponseStatus
}
