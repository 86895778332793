import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import './App.css';
import { PartyPlayView } from './games/partyPlay/PartyPlayView';
import { TravelMain } from './travel/TravelMain';
import { PageTypes } from './types/pages';

function App() {
  const [subDomain, setSubDomain] = useState<string>()
  const [pageType, setPageType] = useState<PageTypes>(PageTypes.MAIN)
  useEffect(() => {
    let host = window.location.host; // gets the full domain of the app

    if (host.startsWith('www.')) {
        host = host.slice(4, host.length)
    }

    const arr = host
      .split('.')
    if (arr.length > 0) 
      setSubDomain(arr[0])
  }, [])
  
  const showView = (page: PageTypes) => {
    if (page === PageTypes.MAIN) {
      return (
        <main className={'main'}>
          <h1 className={'title'}>
            Utility Kit
          </h1>

          <div className={'grid'}>
            <Button variant='outline-primary' onClick={()=> { setPageType(PageTypes.PARTY_PLAY) }}>
              <h2>Party Play</h2>
            </Button>
          </div>
        </main>
      )
    }
    else if (page === PageTypes.PARTY_PLAY) {
      return (<PartyPlayView />)
    }
  }

  return (
    <div className="App">

      <meta name="viewport" content="width=device-width, initial-scale=1" />
      {subDomain === 'travel' ?
        (
          <TravelMain />
        ) : (
          showView(pageType)
        )}
    </div>
  );
}

export default App;
