import { useEffect, useState } from 'react';
import Select from 'react-select';
import './CityPricerView.css';
import { allCityPriceData, cityOptions, currency } from './cities'

export function CityPricerView() {
  const [selectedCity, setSelectedCity] = useState<string | undefined>()

  useEffect(() => {
    // TODO: Load any previous checklists
    const previousCitySdiving = localStorage.getItem('cityPricer.city')
    if (previousCitySdiving) {
      setSelectedCity(previousCitySdiving)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleCityChange = (selectedOption: any) => {
    if (selectedOption) {
      setSelectedCity(selectedOption.value)
    }
  }

  const renderPriceDataRows = (airportCode: string) => {
    const cityPrices = allCityPriceData[airportCode]
    if (!cityPrices)
      return <div></div>
    return <div className='margin-top'>
      <div className='column-holder padding-top-half'>
        <div className='column'>City 🗺: </div>
        <div className='column align-left padding-left'>{cityPrices.name}</div>
      </div>
      {(cityPrices.currency !== currency.USD) && 
        <div className='column-holder padding-top-half'>
          <div className='column'>Currency: </div>
          <div className='column align-left padding-left'>{cityPrices.currency}</div>
        </div>}
      {(cityPrices.avgCoffeeCost) && 
        <div className='column-holder padding-top-half'>
          <div className='column'>Coffee ☕️: </div>
          <div className='column align-left padding-left'>${cityPrices.avgCoffeeCost.toFixed(2)}</div>
        </div>}
      {(cityPrices.uniqueLocalFoods && cityPrices.uniqueLocalFoods.length > 0) && 
        <div className='column-holder padding-top-half'>
          <div className='column padding-right'>Local Food 🥐: </div>
          <div className='column'>
            {cityPrices.uniqueLocalFoods?.map((priceData, index) => {
              return (
                <div key={index} className='column-holder padding-bottom'>
                  <div className='column2 align-right padding-right'>{ priceData.food }</div>
                  <div className='column align-left'>${ priceData.price.toFixed(2) }</div>
                </div>
              )
            })}
          </div>
        </div>}
      {(cityPrices.avgRestaurantCost) && 
        <div className='column-holder padding-top-half'>
          <div className='column'>Restaurant (2 person): </div>
          <div className='column align-left padding-left'>${cityPrices.avgRestaurantCost.toFixed(2)}</div>
        </div>}
      {(cityPrices.avg3StarHotelCost) && 
        <div className='column-holder padding-top-half'>
          <div className='column'>Hotel (3 ⭐️ Stars): </div>
          <div className='column align-left padding-left'>${cityPrices.avg3StarHotelCost.toFixed(2)}</div>
        </div>}
      {(cityPrices.avg4StarHotelCost) && 
        <div className='column-holder padding-top-half'>
          <div className='column'>Hotel (4 ⭐️ Stars): </div>
          <div className='column align-left padding-left'>${cityPrices.avg4StarHotelCost.toFixed(2)}</div>
        </div>}
      {(cityPrices.avg5StarHotelCost) && 
        <div className='column-holder padding-top-half'>
          <div className='column'>Hotel (5 ⭐️ Stars): </div>
          <div className='column align-left padding-left'>${cityPrices.avg5StarHotelCost.toFixed(2)}</div>
        </div>}
      {(cityPrices.avgAirportUber) && 
        <div className='column-holder padding-top-half'>
          <div className='column'>Airport Uber to Downtown 🚘: </div>
          <div className='column align-left padding-left'>${cityPrices.avgAirportUber.toFixed(2)}</div>
        </div>}
      {(cityPrices.avgCarRental) && 
        <div className='column-holder padding-top-half'>
          <div className='column'>Airport Car Rental per Day 🚙: </div>
          <div className='column align-left padding-left'>${cityPrices.avgCarRental.toFixed(2)}</div>
        </div>}
    </div>
  }

  return (
    <div className='center padding-bottom-2em'>
      <h1>City Pricer</h1>
      <div className='side-padding-10vw column-holder padding-top'>
        <div className='column vertical-center align-right padding-right'>
          Where are you going?
        </div>
        <div className='column'>
          <Select
            options={cityOptions}
            placeholder='City'
            onChange={handleCityChange} />
        </div>
      </div>
      <div className='side-padding-10vw'>
        {selectedCity && 
          renderPriceDataRows(selectedCity)}
      </div>
    </div>
  )
}