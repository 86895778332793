import React, { useEffect, useState } from 'react';
import { slide as Menu, State } from 'react-burger-menu'
import './TravelMain.css';
import { UsefulTravelPhrases } from './usefulPhrases/UsefulTravelPhrases'
import { HotelFinderView } from './hotelFinder/HotelFinderView'
import { ThingsToDoView } from './thingsToDo/ThingsToDoView'
import { PackingListView } from './packingList/PackingListView';
import { CityPricerView } from './cityPricer/CityPricerView';

export enum TravelPages {
  usefulPhrases,
  hotelfinder,
  thingsToDo,
  packingList,
  cityPricer,
}

export function TravelMain() {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [selectedTravelPage, setSelectedTravelPage] = useState(TravelPages.usefulPhrases)
  const [detectedCountryIso3, setDetectedCountryIso3] = useState<string | undefined>()

  useEffect(() => {
    fetch('https://ipapi.co/json/')
    .then( res => res.json())
    .then(response => {
      // console.log("Country is : ", response.country_code_iso3);
      if (response.country_code_iso3) {
        setDetectedCountryIso3(response.country_code_iso3)
      }
    })
    .catch((data: any) => {
      console.log('Request failed:', data);
    });
  }, [])

  const handleMenuStateChange = (state: State) => {
    setIsMenuOpen(state.isOpen)
  }

  const closeMenu = () => {
    setIsMenuOpen(false)
  }

  const handleMenuSelect = (page: TravelPages) => {
    setSelectedTravelPage(page)
    closeMenu()
  }
  
  const showTravelPage = (page: TravelPages) => {
    if (page === TravelPages.usefulPhrases) {
      return (<UsefulTravelPhrases
        detectedCountryIso3={detectedCountryIso3} />)
    }
    else if (page === TravelPages.hotelfinder) {
      return (<HotelFinderView />)
    }
    else if (page === TravelPages.thingsToDo) {
      return (<ThingsToDoView />)
    }
    else if (page === TravelPages.packingList) {
      return (<PackingListView />)
    }
    else if (page === TravelPages.cityPricer) {
      return (<CityPricerView />)
    }
  }

  return (
    <div className="App" id="App">
      <Menu
        isOpen={isMenuOpen}
        onStateChange={(state) => handleMenuStateChange(state)}
        disableAutoFocus
        pageWrapId={"page-wrap"}
        outerContainerId={"App"} >
        <div
          onClick={() => handleMenuSelect(TravelPages.usefulPhrases)}
          className="menu-item">
          Useful Phrases
        </div>
        <div
          onClick={() => handleMenuSelect(TravelPages.hotelfinder)}
          className="menu-item">
          Hotel Finder
        </div>
        <div
          onClick={() => handleMenuSelect(TravelPages.thingsToDo)}
          className="menu-item">
          Things To Do
        </div>
        <div
          onClick={() => handleMenuSelect(TravelPages.packingList)}
          className="menu-item">
          Packing List
        </div>
        <div
          onClick={() => handleMenuSelect(TravelPages.cityPricer)}
          className="menu-item">
          City Pricer
        </div>
      </Menu>
      <header className="App-header">
        <div className='center'>
          TRAVEL UTILITIES
        </div>
      </header>
      <div className='App-Body overflow-y' id="page-wrap">
        {showTravelPage(selectedTravelPage)}
      </div>
    </div>
  )
}
