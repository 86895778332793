export enum AirportCode {
  AUS = 'AUS',
  ORD = 'ORD',
  LAS = 'LAS',
  LAX = 'LAX',
  JFK = 'JFK',
  SAN = 'SAN',
  SFO = 'SFO',
  DCA = 'DCA'
}

export const cityOptions = [
  { value: AirportCode.AUS, label: 'Austin, Texas' },
  { value: AirportCode.ORD, label: 'Chicago' },
  { value: AirportCode.LAS, label: 'Las Vegas' },
  { value: AirportCode.LAX, label: 'Los Angeles' },
  { value: AirportCode.JFK, label: 'New York City' },
  { value: AirportCode.SAN, label: 'San Diego' },
  { value: AirportCode.SFO, label: 'San Francisco' },
  { value: AirportCode.DCA, label: 'Washington DC' },
]

export enum currency {
  USD = 'USD',
}

export interface CityPriceData {
  name: string,
  currency: currency,
  avgAirportUber?: number,
  avgCarRental?: number,
  uniqueLocalFoods?: { food: string, price: number, updatedAt: Date }[],
  avgRestaurantCost?: number,
  avgCoffeeCost?: number,
  avg3StarHotelCost?: number,
  avg4StarHotelCost?: number,
  avg5StarHotelCost?: number,
}

export const allCityPriceData: { [key: string]: CityPriceData } = {
  [AirportCode.AUS]: {
    name: 'Austin, TX',
    currency: currency.USD,
    avgAirportUber: 16,
    avgCarRental: 82,
    uniqueLocalFoods: [],
    avgRestaurantCost: 60,
    avgCoffeeCost: 4.80,
    avg3StarHotelCost: 103,
    avg4StarHotelCost: 144,
    avg5StarHotelCost: 321,
  },
  [AirportCode.ORD]: {
    name: 'Chicago, IL',
    currency: currency.USD,
    avgAirportUber: 40,
    avgCarRental: 87,
    uniqueLocalFoods: [],
    avgRestaurantCost: 80,
    avgCoffeeCost: 4.50,
    avg3StarHotelCost: 98,
    avg4StarHotelCost: 115,
    avg5StarHotelCost: 264,
  },
  [AirportCode.LAS]: {
    name: 'Las Vegas, NV',
    currency: currency.USD,
    avgAirportUber: 24,
    avgCarRental: 74,
    uniqueLocalFoods: [],
    avgRestaurantCost: 60,
    avgCoffeeCost: 5.20,
    avg3StarHotelCost: 77,
    avg4StarHotelCost: 80,
    avg5StarHotelCost: 134,
  },
  [AirportCode.LAX]: {
    name: 'Los Angeles, CA',
    currency: currency.USD,
    avgAirportUber: 30,
    avgCarRental: 70,
    uniqueLocalFoods: [
      { food: 'Taco at Leo\'s Tacos Truck 🌮', price: 2.00, updatedAt: new Date('2022-04-11T16:00:00.000Z')},
      { food: '3-Hand Rolls at KazuNori 🌯', price: 14.00, updatedAt: new Date('2022-06-24T16:00:00.000Z')},
      { food: 'Croissant at Republique 🥐', price: 5.00, updatedAt: new Date('2022-06-24T16:00:00.000Z')},
      { food: 'Honey Walnut Shrimp Burger at Bopomofo Cafe 🍔', price: 14.50, updatedAt: new Date('2022-06-24T16:00:00.000Z')},
      { food: 'Beef Roll at Pine and Crane 🫔', price: 6.00, updatedAt: new Date('2022-06-24T16:00:00.000Z')}
    ],
    avgRestaurantCost: 85,
    avgCoffeeCost: 4.80,
    avg3StarHotelCost: 115,
    avg4StarHotelCost: 145,
    avg5StarHotelCost: 692,
  },
  [AirportCode.JFK]: {
    name: 'New York, NY',
    currency: currency.USD,
    avgAirportUber: 69,
    avgCarRental: 92,
    uniqueLocalFoods: [],
    avgRestaurantCost: 100,
    avgCoffeeCost: 4.90,
    avg3StarHotelCost: 202,
    avg4StarHotelCost: 231,
    avg5StarHotelCost: 478,
  },
  [AirportCode.SAN]: {
    name: 'San Diego, CA',
    currency: currency.USD,
    avgAirportUber: 12,
    avgCarRental: 66,
    uniqueLocalFoods: [],
    avgRestaurantCost: 80,
    avgCoffeeCost: 4.70,
    avg3StarHotelCost: 115,
    avg4StarHotelCost: 157,
    avg5StarHotelCost: 179,
  },
  [AirportCode.SFO]: {
    name: 'San Francisco, CA',
    currency: currency.USD,
    avgAirportUber: 30,
    avgCarRental: 80,
    uniqueLocalFoods: [
      { food: 'Kouign Amann Nature at B. Patisserie 🥐', price: 4.00, updatedAt: new Date('2022-06-24T16:00:00.000Z') },
      { food: 'Shui Mai (4) at Good Mong Kok Bakery 🥟', price: 3.20, updatedAt: new Date('2022-06-24T16:00:00.000Z') },
    ],
    avgRestaurantCost: 100,
    avgCoffeeCost: 5.00,
    avg3StarHotelCost: 135,
    avg4StarHotelCost: 163,
    avg5StarHotelCost: 457,
  },
  [AirportCode.DCA]: {
    name: 'Washington DC',
    currency: currency.USD,
    avgAirportUber: 20,
    avgCarRental: 58,
    uniqueLocalFoods: [
      { food: 'Biscuit Sammie at A Baked Joint 🥪', price: 6.50, updatedAt: new Date('2022-06-24T16:00:00.000Z') },
      { food: 'Popcorn Chicken at Jumbo Jumbo Cafe 🍗', price: 8.65, updatedAt: new Date('2022-06-24T16:00:00.000Z') },
      { food: 'Brisket Breakfast Sandwich at Ellē 🥪', price: 12.00, updatedAt: new Date('2022-06-24T16:00:00.000Z') },
    ],
    avgRestaurantCost: 68,
    avgCoffeeCost: 4.70,
    avg3StarHotelCost: 102,
    avg4StarHotelCost: 140,
    avg5StarHotelCost: 263,
  }
}