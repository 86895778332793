import { useEffect, useState } from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { CityWeather, PackingListItems, TravelPreferences } from '../types/packingListTypes';
import { CityCoords } from '../types/TravelTypes';
import './PackingListView.css';
import { CitySelectView } from './subviews/CitySelectView';
import { PackingChecklistView } from './subviews/PackingChecklistView';

export function PackingListView() {
  const [isCitySelectView, setIsCitySelectView] = useState(true)
  const [isPackingListView, setIsPackingListView] = useState(false)
  const [cityCoords, setCityCoords] = useState<CityCoords | undefined>()
  const [cityWeatherData, setCityWeatherData] = useState<CityWeather | undefined>()
  const [travelPreferences, setTravelPreferences] = useState<TravelPreferences>({})
  const [packingListItems, setPackingListItems] = useState<PackingListItems | undefined>()

  useEffect(() => {
    // TODO: Load any previous checklists
    const previousTravelPreferenceString = localStorage.getItem('travelPreferences')
    if (previousTravelPreferenceString) {
      setTravelPreferences(JSON.parse(previousTravelPreferenceString))
    }
    const previousPackingListString = localStorage.getItem('packingListItems')
    if (previousPackingListString) {
      setPackingListItems(JSON.parse(previousPackingListString))
    }
    const previousWeatherDataString = localStorage.getItem('cityWeather')
    if (previousWeatherDataString) {
      setCityWeatherData(JSON.parse(previousWeatherDataString))
    }
    const previousCityCoordsString = localStorage.getItem('cityCoords')
    if (previousCityCoordsString) {
      setCityCoords(JSON.parse(previousCityCoordsString))
    }
    // All necessary data is in localStorage
    if (previousCityCoordsString && previousWeatherDataString && previousPackingListString) {
      handleBreadcrumbClick()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handlePackingListChange = (newPackingList: PackingListItems) => {
    setPackingListItems(newPackingList)
    localStorage.setItem('packingListItems', JSON.stringify(newPackingList))
  }
  
  const handleSelectCity = (selectedCity: CityCoords, cityWeatherData: CityWeather, generatedPackingList: PackingListItems) => {
    // Set selected city/country
    // console.log(` = handleSelectCity - selectedOption: ${JSON.stringify(selectedOption)}`)
    setCityCoords(selectedCity)
    setCityWeatherData(cityWeatherData)
    // console.log(`City set as: ${JSON.stringify(selectedOption)}`)
    localStorage.setItem('cityCoords', JSON.stringify(selectedCity))
    handlePackingListChange(generatedPackingList)
    handleBreadcrumbClick()
  }

  const handleChangeTravelPreferences = (newTravelPreferences: TravelPreferences) => {
    // console.log(`handleChangeTravelPreferences - travelPreferences: ${JSON.stringify(travelPreferences)}`)
    setTravelPreferences(newTravelPreferences)
  }

  const handleBreadcrumbClick = () => {
    setIsCitySelectView(!isCitySelectView)
    setIsPackingListView(!isPackingListView)
  }

  return (
    <div className='center'>
      <Breadcrumb className='margin-left'>
        <Breadcrumb.Item active={isCitySelectView} onClick={() => {
          if (!isCitySelectView) handleBreadcrumbClick()
        }}>Select City</Breadcrumb.Item>
        <Breadcrumb.Item aria-disabled={!!cityCoords} active={isPackingListView} onClick={() => {
          if (!isPackingListView) handleBreadcrumbClick()
        }}>Packing List</Breadcrumb.Item>
      </Breadcrumb>
      {isCitySelectView &&
        <CitySelectView handleSelectCity={handleSelectCity} handleChangeTravelPreferences={handleChangeTravelPreferences} />
      }
      {isPackingListView &&
        <PackingChecklistView cityCoords={cityCoords} travelPreferences={travelPreferences} cityWeatherData={cityWeatherData} packingListItems={packingListItems} handlePackingListChange={handlePackingListChange} />
      }
    </div>
  )
}