import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { ResponseStatus } from '../../types/constants';
import { CitySelectProps, CityWeather, TravelPreferences } from '../../types/packingListTypes';
import { CityCoords } from '../../types/TravelTypes';
import { generatePackingList, getCoordinatesFromCity, getWeather } from '../../utils/weatherUtils';
import './CitySelectView.css';

export function CitySelectView(props: CitySelectProps) {
  const [cityName, setCityName] = useState<string>('');
  const [travelPreferences, setTravelPreferences] = useState<TravelPreferences>({})
  const [isTravellingLight, setIsTravellingLight] = useState<boolean>(false);
  const [isFormalEvent, setIsFormalEvent] = useState<boolean>(false);
  const [isSwimming, setIsSwimming] = useState<boolean>(false);
  const [isHiking, setIsHiking] = useState<boolean>(false);
  const [isCamping, setIsCamping] = useState<boolean>(false);
  const [isFahrenheit, setIsFahrenheit] = useState<boolean>(false);

  useEffect(() => {
    const previousTravelPreferenceString = localStorage.getItem('travelPreferences')
    if (previousTravelPreferenceString) {
      const previousTravelPreferences = JSON.parse(previousTravelPreferenceString)
      // console.log(` = CitySelectView - previousTravelPreferences: ${JSON.stringify(previousTravelPreferences)}`)
      setTravelPreferences(previousTravelPreferences)

      if (previousTravelPreferences.isTravellingLight)
        setIsTravellingLight(previousTravelPreferences.isTravellingLight)
      if (previousTravelPreferences.isFormalEvent)
        setIsFormalEvent(previousTravelPreferences.isFormalEvent)
      if (previousTravelPreferences.isSwimming)
        setIsSwimming(previousTravelPreferences.isSwimming)
      if (previousTravelPreferences.isHiking)
        setIsHiking(previousTravelPreferences.isHiking)
      if (previousTravelPreferences.isCamping)
        setIsCamping(previousTravelPreferences.isCamping)
      if (previousTravelPreferences.isFahrenheit)
        setIsFahrenheit(previousTravelPreferences.isFahrenheit)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleGenerateCity = (cityCoords: CityCoords, travelPrefs: TravelPreferences) => {
    // Get weather
    getWeather(cityCoords.lat, cityCoords.lon).then(weatherResponse => {
      if (weatherResponse.status === ResponseStatus.SUCCESS && weatherResponse.dailyWeather) {
        // Save weather
        generatePackingList(cityCoords, weatherResponse.dailyWeather, travelPrefs).then(generatedList => {
          if (generatedList && cityCoords && weatherResponse.dailyWeather) {
            const newCityWeather: CityWeather = { 
              cityName: cityCoords.name,
              countryCode: cityCoords.country,
              state: cityCoords.state,
              dailyWeather: weatherResponse.dailyWeather,
              createdAt: new Date()
            }
            props.handleSelectCity(cityCoords, newCityWeather, generatedList)
          }
          else {
            // TODO: Error generating list
          }
        })
      }
      else {
        // TODO: Unable to get weather
      }
    })

  }

  const handleGenerateButtonClick = () => {
    if (cityName.length > 0) {
      // TODO: Progress circle to indicate that it's searching
      getCoordinatesFromCity(cityName).then(response => {
        // TODO: Turn off progress circle
        if (response.status === ResponseStatus.SUCCESS) {
          // console.log(`OK`)
          if (response.cities?.length === 1) {
            // console.log(`city[0]: ${JSON.stringify(response.cities[0])}`)
            handleGenerateCity(response.cities[0], travelPreferences)
          }
          else if (response.cities && response.cities.length > 1) {
            // Multiple matches found
            // console.log(` ~ Multiple matches -> city: ${JSON.stringify(response.cities)}`)
            if (response.cities[0].name.toUpperCase().trim() === cityName.split(',')[0].toUpperCase().trim()) {
              // console.log(` - city match => city[0]: ${JSON.stringify(response.cities[0])}`)
              handleGenerateCity(response.cities[0], travelPreferences)
            }
            else {
              // Ask user to select the correct one
            }
          }
          else {
            // No matching cities found
          }
        }
        else {
          console.log(`Unable to query API`)
          // TODO: Warn that lookup was unsuccessful
        }
      })
    }
    else {
      // TODO: Notify that input is required
    }
  }

  return (
    <div className='center padding-bottom'>
      <h3 className='center padding-top'>
        Where are you traveling?
      </h3>
      <form className='side-padding-10vw padding-top'>
        <input
          type='text'
          placeholder='example: London, GB'
          className='min-width-250'
          value={cityName}
          onChange={(e) => setCityName(e.target.value)} />
      </form>
      <Button variant='primary' onClick={() => handleGenerateButtonClick()}className='margin-top'>Generate Packing List</Button>
      <div className='side-padding-10vw padding-top-2em'>
        <div className='padding-top column-holder'>
          <div className='column-3 vertical-center center'>
            {`Travelling Light?`}
          </div>
          <ToggleButtonGroup
            color="primary"
            value={isTravellingLight ? 'Yes' : 'No'}
            exclusive
            onChange={() => {
              setIsTravellingLight(!isTravellingLight)
              let newTravelPreferences = travelPreferences
              newTravelPreferences.isTravellingLight = !travelPreferences.isTravellingLight
              setTravelPreferences(newTravelPreferences)
              props.handleChangeTravelPreferences(newTravelPreferences)
            }}
            className='padding-left column align-left'
          >
            <ToggleButton value="Yes">Yes</ToggleButton>
            <ToggleButton value="No">No</ToggleButton>
            </ToggleButtonGroup>
        </div>
        <div className='padding-top column-holder'>
          <div className='column-3 vertical-center center'>
            {`Formal Event?`}
          </div>
          <ToggleButtonGroup
            color="primary"
            value={isFormalEvent ? 'Yes' : 'No'}
            exclusive
            onChange={() => {
              setIsFormalEvent(!isFormalEvent)
              let newTravelPreferences = travelPreferences
              newTravelPreferences.isFormalEvent = !travelPreferences.isFormalEvent
              setTravelPreferences(newTravelPreferences)
              props.handleChangeTravelPreferences(newTravelPreferences)
            }}
            className='padding-left column align-left'
          >
            <ToggleButton value="Yes">Yes</ToggleButton>
            <ToggleButton value="No">No</ToggleButton>
            </ToggleButtonGroup>
        </div>
        <div className='padding-top column-holder'>
          <div className='column-3 vertical-center center'>
            {`Pool / Beach?`}
          </div>
          <ToggleButtonGroup
            color="primary"
            value={isSwimming ? 'Yes' : 'No'}
            exclusive
            onChange={() => {
              setIsSwimming(!isSwimming)
              let newTravelPreferences = travelPreferences
              newTravelPreferences.isSwimming = !travelPreferences.isSwimming
              setTravelPreferences(newTravelPreferences)
              props.handleChangeTravelPreferences(newTravelPreferences)
            }}
            className='padding-left column align-left'
          >
            <ToggleButton value="Yes">Yes</ToggleButton>
            <ToggleButton value="No">No</ToggleButton>
            </ToggleButtonGroup>
        </div>
        <div className='padding-top column-holder'>
          <div className='column-3 vertical-center center'>
            {`Going Hiking?`}
          </div>
          <ToggleButtonGroup
            color="primary"
            value={isHiking ? 'Yes' : 'No'}
            exclusive
            onChange={() => {
              setIsHiking(!isHiking)
              let newTravelPreferences = travelPreferences
              newTravelPreferences.isHiking = !travelPreferences.isHiking
              setTravelPreferences(newTravelPreferences)
              props.handleChangeTravelPreferences(newTravelPreferences)
            }}
            className='padding-left column align-left'
          >
            <ToggleButton value="Yes">Yes</ToggleButton>
            <ToggleButton value="No">No</ToggleButton>
            </ToggleButtonGroup>
        </div>
        <div className='padding-top column-holder'>
          <div className='column-3 vertical-center center'>
            {`Going Camping?`}
          </div>
          <ToggleButtonGroup
            color="primary"
            value={isCamping ? 'Yes' : 'No'}
            exclusive
            onChange={() => {
              setIsCamping(!isCamping)
              let newTravelPreferences = travelPreferences
              newTravelPreferences.isCamping = !travelPreferences.isCamping
              setTravelPreferences(newTravelPreferences)
              props.handleChangeTravelPreferences(newTravelPreferences)
            }}
            className='padding-left column align-left'
          >
            <ToggleButton value="Yes">Yes</ToggleButton>
            <ToggleButton value="No">No</ToggleButton>
            </ToggleButtonGroup>
        </div>
        <div className='padding-top-2em column-holder'>
          <div className='column-3 vertical-center center'>
            {`Temperature Unit`}
          </div>
          <ToggleButtonGroup
            color="primary"
            value={isFahrenheit ? '°F' : '°C'}
            exclusive
            onChange={() => {
              setIsFahrenheit(!isFahrenheit)
              let newTravelPreferences = travelPreferences
              newTravelPreferences.isFahrenheit = !travelPreferences.isFahrenheit
              setTravelPreferences(newTravelPreferences)
              props.handleChangeTravelPreferences(newTravelPreferences)
              localStorage.setItem('travelPreferences', JSON.stringify(newTravelPreferences))
            }}
            className='padding-left column align-left'
          >
            <ToggleButton value="°F">°F</ToggleButton>
            <ToggleButton value="°C">°C</ToggleButton>
            </ToggleButtonGroup>
        </div>
      </div>
    </div>
  )
}