import { useEffect } from 'react';
import { Checkbox } from '@mui/material';
import Icon from '@mdi/react'
import { PackingChecklistProps, packingItem } from '../../types/packingListTypes';
import './PackingChecklistView.css';
import { formatTemperature, getWeatherIcon } from '../../utils/weatherUtils';
import { formatUnixAsDate } from '../../utils/dateUtils';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export function PackingChecklistView(props: PackingChecklistProps) {

  useEffect(() => {

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleRowClick = (index: number) => {
    if (props.packingListItems) {
      props.packingListItems.packingItems[index].isChecked = !props.packingListItems.packingItems[index].isChecked
      const newPackingListItems = { packingItems: props.packingListItems.packingItems, createdAt: new Date(), cityName: props.packingListItems.cityName, countryCode: props.packingListItems.countryCode, state: props.packingListItems.state }
      props.handlePackingListChange(newPackingListItems)
    }
  }

  const renderWeatherRow = (dailyWeather: any[]) => {
    return <div className='side-padding-5vw'>
      <div className='horizontal-scroll top-bottom-padding-1em center'>
        {dailyWeather?.map((thisWeather, index) => {
          return (
            <div className='side-padding-1em' key={index}>
              <OverlayTrigger
                key={`overlay ${index}`}
                placement='bottom'
                overlay={
                  <Tooltip id={`tooltip-${index}`}>
                    <div><strong>{thisWeather.weather[0].description}</strong></div>
                    <div className='margin-bottom'>{formatUnixAsDate(thisWeather.dt)}</div>
                    <div>Feels like</div>
                    <div className='align-right'>morning: {formatTemperature(thisWeather.feels_like.morn, props.travelPreferences.isFahrenheit ?? false)}</div>
                    <div className='align-right'>daytime: {formatTemperature(thisWeather.feels_like.day, props.travelPreferences.isFahrenheit ?? false)}</div>
                    <div className='align-right'>evening: {formatTemperature(thisWeather.feels_like.eve, props.travelPreferences.isFahrenheit ?? false)}</div>
                    <div className='align-right'>night: {formatTemperature(thisWeather.feels_like.night, props.travelPreferences.isFahrenheit ?? false)}</div>
                  </Tooltip>
                }
              >
                <div>
                  <Icon path={getWeatherIcon(thisWeather.weather[0].id)}
                    title='rain'
                    size={1}
                  />
                  {formatTemperature(thisWeather.feels_like.day, props.travelPreferences.isFahrenheit ?? false)}
                </div>
              </OverlayTrigger>
            </div>
          )
        })}
      </div>
    </div>
  }

  const renderCheckboxRows = (packingItems: packingItem[]) => {
    return <div className='margin-top margin-bottom-3em'>
      {packingItems?.map((packingItem, index) => {
        return (
          <div key={index} onClick={() => handleRowClick(index)} className='align-left side-margins-2em bottom-border'>
            <Checkbox checked={packingItems[index].isChecked} />
            {packingItem.item}
          </div>
        )
      })}
    </div>
  }

  return (
    <div className='center'>
      <h3 className='side-padding-10vw padding-top horizontal-flex center'>
        <div>
          {props.cityCoords && 
            `Packing list for ` + props.cityCoords.name + `, ` + props.cityCoords.country
            }
        </div>
        {/* <div className='padding-left'>
          <Icon path={mdiDotsHorizontalCircleOutline}
            title='menu-dots'
            size={1}
            />
        </div> */}
      </h3>
      {props.cityWeatherData?.dailyWeather && renderWeatherRow(props.cityWeatherData.dailyWeather)}
      <div className='margin-top'>
        {props.packingListItems && renderCheckboxRows(props.packingListItems.packingItems)}
      </div>
    </div>
  )
}
