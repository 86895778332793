export const langCode2ToLangName: { [key: string]: string } = {
  'AB': 'Abkhazian',
  'AA': 'Afar',
  'AF': 'Afrikaans',
  'SQ': 'Albanian',
  'AM': 'Amharic',
  'AR': 'Arabic',
  'HY': 'Armenian',
  'AS': 'Assamese',
  'AY': 'Aymara',
  'AZ': 'Azerbaijani',
  'BA': 'Bashkir',
  'EU': 'Basque',
  'BN': 'Bengali',
  'DZ': 'Bhutani',
  'BH': 'Bihari',
  'BI': 'Bislama',
  'BR': 'Breton',
  'BG': 'Bulgarian',
  'MY': 'Burmese',
  'BE': 'Byelorussian',
  'KM': 'Cambodian',
  'CA': 'Catalan',
  'ZH': 'Chinese',
  'CO': 'Corsican',
  'HR': 'Croatian',
  'CS': 'Czech',
  'DA': 'Danish',
  'NL': 'Dutch',
  'EN': 'English',
  'EO': 'Esperanto',
  'ET': 'Estonian',
  'FO': 'Faeroese',
  'FJ': 'Fiji',
  'FI': 'Finnish',
  'FR': 'French',
  'FY': 'Frisian',
  'GD': 'Gaelic',
  'GL': 'Galician',
  'KA': 'Georgian',
  'DE': 'German',
  'EL': 'Greek',
  'KL': 'Greenlandic',
  'GN': 'Guarani',
  'GU': 'Gujarati',
  'HA': 'Hausa',
  'IW': 'Hebrew',
  'HI': 'Hindi',
  'HU': 'Hungarian',
  'IS': 'Icelandic',
  'IN': 'Indonesian',
  'IA': 'Interlingua',
  'IE': 'Interlingue',
  'IK': 'Inupiak',
  'GA': 'Irish',
  'IT': 'Italian',
  'JA': 'Japanese',
  'JW': 'Javanese',
  'KN': 'Kannada',
  'KS': 'Kashmiri',
  'KK': 'Kazakh',
  'RW': 'Kinyarwanda',
  'KY': 'Kirghiz',
  'RN': 'Kirundi',
  'KO': 'Korean',
  'KU': 'Kurdish',
  'LO': 'Laothian',
  'LA': 'Latin',
  'LV': 'Latvian',
  'LN': 'Lingala',
  'LT': 'Lithuanian',
  'MK': 'Macedonian',
  'MG': 'Malagasy',
  'MS': 'Malay',
  'ML': 'Malayalam',
  'MT': 'Maltese',
  'MI': 'Maori',
  'MR': 'Marathi',
  'MO': 'Moldavian',
  'MN': 'Mongolian',
  'NA': 'Nauru',
  'NE': 'Nepali',
  'NO': 'Norwegian',
  'OC': 'Occitan',
  'OR': 'Oriya',
  'OM': 'Oromo',
  'PS': 'Pashto',
  'FA': 'Persian',
  'PL': 'Polish',
  'PT': 'Portuguese',
  'PA': 'Punjabi',
  'QU': 'Quechua',
  'RM': 'Rhaeto-Romance',
  'RO': 'Romanian',
  'RU': 'Russian',
  'SM': 'Samoan',
  'SG': 'Sangro',
  'SA': 'Sanskrit',
  'SR': 'Serbian',
  'SH': 'Serbo-Croatian',
  'ST': 'Sesotho',
  'TN': 'Setswana',
  'SN': 'Shona',
  'SD': 'Sindhi',
  'SI': 'Singhalese',
  'SS': 'Siswati',
  'SK': 'Slovak',
  'SL': 'Slovenian',
  'SO': 'Somali',
  'ES': 'Spanish',
  'SU': 'Sudanese',
  'SW': 'Swahili',
  'SV': 'Swedish',
  'TL': 'Tagalog',
  'TG': 'Tajik',
  'TA': 'Tamil',
  'TT': 'Tatar',
  'TE': 'Tegulu',
  'TH': 'Thai',
  'BO': 'Tibetan',
  'TI': 'Tigrinya',
  'TO': 'Tonga',
  'TS': 'Tsonga',
  'TR': 'Turkish',
  'TK': 'Turkmen',
  'TW': 'Twi',
  'UK': 'Ukrainian',
  'UR': 'Urdu',
  'UZ': 'Uzbek',
  'VI': 'Vietnamese',
  'VO': 'Volapuk',
  'CY': 'Welsh',
  'WO': 'Wolof',
  'XH': 'Xhosa',
  'JI': 'Yiddish',
  'YO': 'Yoruba',
  'ZU': 'Zulu'
}

export interface countryLang {
  countryName: string;
  officialLanguageCodes: string[];
}

export const countryLangObjects: { [key: string]: countryLang } = {
  "ABW": {
    "countryName": "Aruba",
    "officialLanguageCodes": [
      "nl",
      "pap"
    ]
  },
  "AFG": {
    "countryName": "Afghanistan",
    "officialLanguageCodes": [
      "fa",
      "ps"
    ]
  },
  "AGO": {
    "countryName": "Angola",
    "officialLanguageCodes": [
      "pt"
    ]
  },
  "AIA": {
    "countryName": "Anguilla",
    "officialLanguageCodes": [
      "en"
    ]
  },
  "ALA": {
    "countryName": "Aland Islands",
    "officialLanguageCodes": [
      "sv"
    ]
  },
  "ALB": {
    "countryName": "Albania",
    "officialLanguageCodes": [
      "sq"
    ]
  },
  "AND": {
    "countryName": "Andorra",
    "officialLanguageCodes": [
      "ca"
    ]
  },
  "ARE": {
    "countryName": "United Arab Emirates",
    "officialLanguageCodes": [
      "ar"
    ]
  },
  "ARG": {
    "countryName": "Argentina",
    "officialLanguageCodes": [
      "es"
    ]
  },
  "ARM": {
    "countryName": "Armenia",
    "officialLanguageCodes": [
      "hy"
    ]
  },
  "ASM": {
    "countryName": "American Samoa",
    "officialLanguageCodes": [
      "en",
      "sm"
    ]
  },
  "ATA": {
    "countryName": "Antarctica",
    "officialLanguageCodes": [
      "en",
      "es",
      "fr",
      "ru"
    ]
  },
  "ATF": {
    "countryName": "French Southern Territories",
    "officialLanguageCodes": [
      "fr"
    ]
  },
  "ATG": {
    "countryName": "Antigua and Barbuda",
    "officialLanguageCodes": [
      "en"
    ]
  },
  "AUS": {
    "countryName": "Australia",
    "officialLanguageCodes": [
      "en"
    ]
  },
  "AUT": {
    "countryName": "Austria",
    "officialLanguageCodes": [
      "de"
    ]
  },
  "AZE": {
    "countryName": "Azerbaijan",
    "officialLanguageCodes": [
      "az"
    ]
  },
  "BDI": {
    "countryName": "Burundi",
    "officialLanguageCodes": [
      "fr"
    ]
  },
  "BEL": {
    "countryName": "Belgium",
    "officialLanguageCodes": [
      "nl",
      "fr",
      "de"
    ]
  },
  "BEN": {
    "countryName": "Benin",
    "officialLanguageCodes": [
      "fr"
    ]
  },
  "BES": {
    "countryName": "Bonaire, Sint Eustatius and Saba",
    "officialLanguageCodes": [
      "nl"
    ]
  },
  "BFA": {
    "countryName": "Burkina Faso",
    "officialLanguageCodes": [
      "fr"
    ]
  },
  "BGD": {
    "countryName": "Bangladesh",
    "officialLanguageCodes": [
      "bn"
    ]
  },
  "BGR": {
    "countryName": "Bulgaria",
    "officialLanguageCodes": [
      "bg"
    ]
  },
  "BHR": {
    "countryName": "Bahrain",
    "officialLanguageCodes": [
      "ar"
    ]
  },
  "BHS": {
    "countryName": "Bahamas",
    "officialLanguageCodes": [
      "en"
    ]
  },
  "BIH": {
    "countryName": "Bosnia and Herzegovina",
    "officialLanguageCodes": [
      "bs",
      "hr",
      "sr"
    ]
  },
  "BLM": {
    "countryName": "Saint Barthélemy",
    "officialLanguageCodes": [
      "fr"
    ]
  },
  "BLR": {
    "countryName": "Belarus",
    "officialLanguageCodes": [
      "be",
      "ru"
    ]
  },
  "BLZ": {
    "countryName": "Belize",
    "officialLanguageCodes": [
      "en"
    ]
  },
  "BMU": {
    "countryName": "Bermuda",
    "officialLanguageCodes": [
      "en"
    ]
  },
  "BOL": {
    "countryName": "Bolivia",
    "officialLanguageCodes": [
      "es",
      "qu",
      "gn",
      "ay"
    ]
  },
  "BRA": {
    "countryName": "Brazil",
    "officialLanguageCodes": [
      "pt"
    ]
  },
  "BRB": {
    "countryName": "Barbados",
    "officialLanguageCodes": [
      "en"
    ]
  },
  "BRN": {
    "countryName": "Brunei Darussalam",
    "officialLanguageCodes": [
      "ms"
    ]
  },
  "BTN": {
    "countryName": "Bhutan",
    "officialLanguageCodes": [
      "dz"
    ]
  },
  "BVT": {
    "countryName": "Bouvet Island",
    "officialLanguageCodes": [
      "no"
    ]
  },
  "BWA": {
    "countryName": "Botswana",
    "officialLanguageCodes": [
      "en",
      "tn"
    ]
  },
  "CAF": {
    "countryName": "Central African Republic",
    "officialLanguageCodes": [
      "fr",
      "sg"
    ]
  },
  "CAN": {
    "countryName": "Canada",
    "officialLanguageCodes": [
      "en",
      "fr"
    ]
  },
  "CCK": {
    "countryName": "Cocos (Keeling) Islands",
    "officialLanguageCodes": [
      "en"
    ]
  },
  "CHE": {
    "countryName": "Switzerland",
    "officialLanguageCodes": [
      "de",
      "fr",
      "it",
      // "rm"
    ]
  },
  "CHL": {
    "countryName": "Chile",
    "officialLanguageCodes": [
      "es"
    ]
  },
  "CHN": {
    "countryName": "China",
    "officialLanguageCodes": [
      "zh-hans"
    ]
  },
  "CIV": {
    "countryName": "Côte d'Ivoire",
    "officialLanguageCodes": [
      "fr"
    ]
  },
  "CMR": {
    "countryName": "Cameroon",
    "officialLanguageCodes": [
      "fr",
      "en"
    ]
  },
  "COD": {
    "countryName": "Congo, the Democratic Republic of the",
    "officialLanguageCodes": [
      "fr"
    ]
  },
  "COG": {
    "countryName": "Congo",
    "officialLanguageCodes": [
      "fr"
    ]
  },
  "COK": {
    "countryName": "Cook Islands",
    "officialLanguageCodes": [
      "en",
      "rar"
    ]
  },
  "COL": {
    "countryName": "Colombia",
    "officialLanguageCodes": [
      "es"
    ]
  },
  "COM": {
    "countryName": "Comoros",
    "officialLanguageCodes": [
      "ar",
      "fr",
      "sw"
    ]
  },
  "CPV": {
    "countryName": "Cape Verde",
    "officialLanguageCodes": [
      "pt"
    ]
  },
  "CRI": {
    "countryName": "Costa Rica",
    "officialLanguageCodes": [
      "es"
    ]
  },
  "CUB": {
    "countryName": "Cuba",
    "officialLanguageCodes": [
      "es"
    ]
  },
  "CUW": {
    "countryName": "Curaçao",
    "officialLanguageCodes": [
      "nl",
      "en"
    ]
  },
  "CXR": {
    "countryName": "Christmas Island",
    "officialLanguageCodes": [
      "en"
    ]
  },
  "CYM": {
    "countryName": "Cayman Islands",
    "officialLanguageCodes": [
      "en"
    ]
  },
  "CYP": {
    "countryName": "Cyprus",
    "officialLanguageCodes": [
      "el",
      "tr"
    ]
  },
  "CZE": {
    "countryName": "Czech Republic",
    "officialLanguageCodes": [
      "cs"
    ]
  },
  "DEU": {
    "countryName": "Germany",
    "officialLanguageCodes": [
      "de"
    ]
  },
  "DJI": {
    "countryName": "Djibouti",
    "officialLanguageCodes": [
      "fr",
      "ar",
      "so",
      "aa"
    ]
  },
  "DMA": {
    "countryName": "Dominica",
    "officialLanguageCodes": [
      "en"
    ]
  },
  "DNK": {
    "countryName": "Denmark",
    "officialLanguageCodes": [
      "da"
    ]
  },
  "DOM": {
    "countryName": "Dominican Republic",
    "officialLanguageCodes": [
      "es"
    ]
  },
  "DZA": {
    "countryName": "Algeria",
    "officialLanguageCodes": [
      "ar"
    ]
  },
  "ECU": {
    "countryName": "Ecuador",
    "officialLanguageCodes": [
      "es"
    ]
  },
  "EGY": {
    "countryName": "Egypt",
    "officialLanguageCodes": [
      "ar"
    ]
  },
  "ERI": {
    "countryName": "Eritrea",
    "officialLanguageCodes": [
      "ti",
      "ar",
      "en"
    ]
  },
  "ESH": {
    "countryName": "Western Sahara",
    "officialLanguageCodes": [
      "ar",
      "es",
      "fr"
    ]
  },
  "ESP": {
    "countryName": "Spain",
    "officialLanguageCodes": [
      "ast",
      "ca",
      "es",
      "eu",
      "gl"
    ]
  },
  "EST": {
    "countryName": "Estonia",
    "officialLanguageCodes": [
      "et"
    ]
  },
  "ETH": {
    "countryName": "Ethiopia",
    "officialLanguageCodes": [
      "am",
      "om"
    ]
  },
  "FIN": {
    "countryName": "Finland",
    "officialLanguageCodes": [
      "fi",
      "sv",
      "se"
    ]
  },
  "FJI": {
    "countryName": "Fiji",
    "officialLanguageCodes": [
      "en"
    ]
  },
  "FLK": {
    "countryName": "Falkland Islands (Malvinas)",
    "officialLanguageCodes": [
      "en"
    ]
  },
  "FRA": {
    "countryName": "France",
    "officialLanguageCodes": [
      "fr"
    ]
  },
  "FRO": {
    "countryName": "Faroe Islands",
    "officialLanguageCodes": [
      "fo",
      "da"
    ]
  },
  "FSM": {
    "countryName": "Micronesia, Federated States of",
    "officialLanguageCodes": [
      "en"
    ]
  },
  "GAB": {
    "countryName": "Gabon",
    "officialLanguageCodes": [
      "fr"
    ]
  },
  "GBR": {
    "countryName": "United Kingdom",
    "officialLanguageCodes": [
      "en",
      "ga",
      "cy",
      "gd",
      "kw"
    ]
  },
  "GEO": {
    "countryName": "Georgia",
    "officialLanguageCodes": [
      "ka"
    ]
  },
  "GGY": {
    "countryName": "Guernsey",
    "officialLanguageCodes": [
      "en"
    ]
  },
  "GHA": {
    "countryName": "Ghana",
    "officialLanguageCodes": [
      "en"
    ]
  },
  "GIB": {
    "countryName": "Gibraltar",
    "officialLanguageCodes": [
      "en"
    ]
  },
  "GIN": {
    "countryName": "Guinea",
    "officialLanguageCodes": [
      "fr"
    ]
  },
  "GLP": {
    "countryName": "Guadeloupe",
    "officialLanguageCodes": [
      "fr"
    ]
  },
  "GMB": {
    "countryName": "Gambia",
    "officialLanguageCodes": [
      "en"
    ]
  },
  "GNB": {
    "countryName": "Guinea-Bissau",
    "officialLanguageCodes": [
      "pt"
    ]
  },
  "GNQ": {
    "countryName": "Equatorial Guinea",
    "officialLanguageCodes": [
      "es",
      "fr",
      "pt"
    ]
  },
  "GRC": {
    "countryName": "Greece",
    "officialLanguageCodes": [
      "el"
    ]
  },
  "GRD": {
    "countryName": "Grenada",
    "officialLanguageCodes": [
      "en"
    ]
  },
  "GRL": {
    "countryName": "Greenland",
    "officialLanguageCodes": [
      "kl",
      "da"
    ]
  },
  "GTM": {
    "countryName": "Guatemala",
    "officialLanguageCodes": [
      "es"
    ]
  },
  "GUF": {
    "countryName": "French Guiana",
    "officialLanguageCodes": [
      "fr"
    ]
  },
  "GUM": {
    "countryName": "Guam",
    "officialLanguageCodes": [
      "en",
      "ch"
    ]
  },
  "GUY": {
    "countryName": "Guyana",
    "officialLanguageCodes": [
      "en"
    ]
  },
  "HKG": {
    "countryName": "Hong Kong",
    "officialLanguageCodes": [
      "zh-hant",
      "en"
    ]
  },
  "HMD": {
    "countryName": "Heard Island and McDonald Islands",
    "officialLanguageCodes": [
      "en"
    ]
  },
  "HND": {
    "countryName": "Honduras",
    "officialLanguageCodes": [
      "es"
    ]
  },
  "HRV": {
    "countryName": "Croatia",
    "officialLanguageCodes": [
      "hr"
    ]
  },
  "HTI": {
    "countryName": "Haiti",
    "officialLanguageCodes": [
      "fr",
      "ht"
    ]
  },
  "HUN": {
    "countryName": "Hungary",
    "officialLanguageCodes": [
      "hu"
    ]
  },
  "IDN": {
    "countryName": "Indonesia",
    "officialLanguageCodes": [
      "id"
    ]
  },
  "IMN": {
    "countryName": "Isle of Man",
    "officialLanguageCodes": [
      "en"
    ]
  },
  "IND": {
    "countryName": "India",
    "officialLanguageCodes": [
      "hi",
      "en"
    ]
  },
  "IOT": {
    "countryName": "British Indian Ocean Territory",
    "officialLanguageCodes": [
      "en"
    ]
  },
  "IRL": {
    "countryName": "Ireland",
    "officialLanguageCodes": [
      "en",
      "ga"
    ]
  },
  "IRN": {
    "countryName": "Iran",
    "officialLanguageCodes": [
      "fa"
    ]
  },
  "IRQ": {
    "countryName": "Iraq",
    "officialLanguageCodes": [
      "ar",
      "ku"
    ]
  },
  "ISL": {
    "countryName": "Iceland",
    "officialLanguageCodes": [
      "is"
    ]
  },
  "ISR": {
    "countryName": "Israel",
    "officialLanguageCodes": [
      "he"
    ]
  },
  "ITA": {
    "countryName": "Italy",
    "officialLanguageCodes": [
      "it",
      "de",
      "fr"
    ]
  },
  "JAM": {
    "countryName": "Jamaica",
    "officialLanguageCodes": [
      "en"
    ]
  },
  "JEY": {
    "countryName": "Jersey",
    "officialLanguageCodes": [
      "en"
    ]
  },
  "JOR": {
    "countryName": "Jordan",
    "officialLanguageCodes": [
      "ar"
    ]
  },
  "JPN": {
    "countryName": "Japan",
    "officialLanguageCodes": [
      "ja"
    ]
  },
  "KAZ": {
    "countryName": "Kazakhstan",
    "officialLanguageCodes": [
      "kk",
      "ru"
    ]
  },
  "KEN": {
    "countryName": "Kenya",
    "officialLanguageCodes": [
      "sw",
      "en"
    ]
  },
  "KGZ": {
    "countryName": "Kyrgyzstan",
    "officialLanguageCodes": [
      "ky",
      "ru"
    ]
  },
  "KHM": {
    "countryName": "Cambodia",
    "officialLanguageCodes": [
      "km"
    ]
  },
  "KIR": {
    "countryName": "Kiribati",
    "officialLanguageCodes": [
      "en"
    ]
  },
  "KNA": {
    "countryName": "Saint Kitts and Nevis",
    "officialLanguageCodes": [
      "en"
    ]
  },
  "KOR": {
    "countryName": "South Korea",
    "officialLanguageCodes": [
      "ko"
    ]
  },
  "KWT": {
    "countryName": "Kuwait",
    "officialLanguageCodes": [
      "ar"
    ]
  },
  "LAO": {
    "countryName": "Laos",
    "officialLanguageCodes": [
      "lo"
    ]
  },
  "LBN": {
    "countryName": "Lebanon",
    "officialLanguageCodes": [
      "ar",
      "fr"
    ]
  },
  "LBR": {
    "countryName": "Liberia",
    "officialLanguageCodes": [
      "en"
    ]
  },
  "LBY": {
    "countryName": "Libya",
    "officialLanguageCodes": [
      "ar"
    ]
  },
  "LCA": {
    "countryName": "Saint Lucia",
    "officialLanguageCodes": [
      "en"
    ]
  },
  "LIE": {
    "countryName": "Liechtenstein",
    "officialLanguageCodes": [
      "de"
    ]
  },
  "LKA": {
    "countryName": "Sri Lanka",
    "officialLanguageCodes": [
      "si",
      "ta"
    ]
  },
  "LSO": {
    "countryName": "Lesotho",
    "officialLanguageCodes": [
      "en",
      "st"
    ]
  },
  "LTU": {
    "countryName": "Lithuania",
    "officialLanguageCodes": [
      "lt"
    ]
  },
  "LUX": {
    "countryName": "Luxembourg",
    "officialLanguageCodes": [
      "lb",
      "fr",
      "de"
    ]
  },
  "LVA": {
    "countryName": "Latvia",
    "officialLanguageCodes": [
      "lv"
    ]
  },
  "MAC": {
    "countryName": "Macao",
    "officialLanguageCodes": [
      "zh-hant",
      "pt"
    ]
  },
  "MAF": {
    "countryName": "Saint Martin (French part)",
    "officialLanguageCodes": [
      "fr"
    ]
  },
  "MAR": {
    "countryName": "Morocco",
    "officialLanguageCodes": [
      "ar",
      "fr",
      "zgh"
    ]
  },
  "MCO": {
    "countryName": "Monaco",
    "officialLanguageCodes": [
      "fr"
    ]
  },
  "MDA": {
    "countryName": "Moldova",
    "officialLanguageCodes": [
      "ro",
      "ru",
      "uk"
    ]
  },
  "MDG": {
    "countryName": "Madagascar",
    "officialLanguageCodes": [
      "mg",
      "fr"
    ]
  },
  "MDV": {
    "countryName": "Maldives",
    "officialLanguageCodes": [
      "dv"
    ]
  },
  "MEX": {
    "countryName": "Mexico",
    "officialLanguageCodes": [
      "es"
    ]
  },
  "MHL": {
    "countryName": "Marshall Islands",
    "officialLanguageCodes": [
      "en",
      "mh"
    ]
  },
  "MKD": {
    "countryName": "Macedonia",
    "officialLanguageCodes": [
      "mk"
    ]
  },
  "MLI": {
    "countryName": "Mali",
    "officialLanguageCodes": [
      "fr"
    ]
  },
  "MLT": {
    "countryName": "Malta",
    "officialLanguageCodes": [
      "mt",
      "en"
    ]
  },
  "MMR": {
    "countryName": "Myanmar",
    "officialLanguageCodes": [
      "my"
    ]
  },
  "MNE": {
    "countryName": "Montenegro",
    "officialLanguageCodes": [
      "srp",
      "sr",
      "hr",
      "bs",
      "sq"
    ]
  },
  "MNG": {
    "countryName": "Mongolia",
    "officialLanguageCodes": [
      "mn"
    ]
  },
  "MNP": {
    "countryName": "Northern Mariana Islands",
    "officialLanguageCodes": [
      "en",
      "ch"
    ]
  },
  "MOZ": {
    "countryName": "Mozambique",
    "officialLanguageCodes": [
      "pt"
    ]
  },
  "MRT": {
    "countryName": "Mauritania",
    "officialLanguageCodes": [
      "ar",
      "fr"
    ]
  },
  "MSR": {
    "countryName": "Montserrat",
    "officialLanguageCodes": [
      "en"
    ]
  },
  "MTQ": {
    "countryName": "Martinique",
    "officialLanguageCodes": [
      "fr"
    ]
  },
  "MUS": {
    "countryName": "Mauritius",
    "officialLanguageCodes": [
      "mfe",
      "fr",
      "en"
    ]
  },
  "MWI": {
    "countryName": "Malawi",
    "officialLanguageCodes": [
      "en",
      "ny"
    ]
  },
  "MYS": {
    "countryName": "Malaysia",
    "officialLanguageCodes": [
      "ms"
    ]
  },
  "MYT": {
    "countryName": "Mayotte",
    "officialLanguageCodes": [
      "fr"
    ]
  },
  "NAM": {
    "countryName": "Namibia",
    "officialLanguageCodes": [
      "en",
      "sf",
      "de"
    ]
  },
  "NCL": {
    "countryName": "New Caledonia",
    "officialLanguageCodes": [
      "fr"
    ]
  },
  "NER": {
    "countryName": "Niger",
    "officialLanguageCodes": [
      "fr"
    ]
  },
  "NFK": {
    "countryName": "Norfolk Island",
    "officialLanguageCodes": [
      "en",
      "pih"
    ]
  },
  "NGA": {
    "countryName": "Nigeria",
    "officialLanguageCodes": [
      "en"
    ]
  },
  "NIC": {
    "countryName": "Nicaragua",
    "officialLanguageCodes": [
      "es"
    ]
  },
  "NIU": {
    "countryName": "Niue",
    "officialLanguageCodes": [
      "niu",
      "en"
    ]
  },
  "NLD": {
    "countryName": "Netherlands",
    "officialLanguageCodes": [
      "nl"
    ]
  },
  "NOR": {
    "countryName": "Norway",
    "officialLanguageCodes": [
      "nb",
      "nn",
      "no",
      "se"
    ]
  },
  "NPL": {
    "countryName": "Nepal",
    "officialLanguageCodes": [
      "ne"
    ]
  },
  "NRU": {
    "countryName": "Nauru",
    "officialLanguageCodes": [
      "na",
      "en"
    ]
  },
  "NZL": {
    "countryName": "New Zealand",
    "officialLanguageCodes": [
      "mi",
      "en"
    ]
  },
  "OMN": {
    "countryName": "Oman",
    "officialLanguageCodes": [
      "ar"
    ]
  },
  "PAK": {
    "countryName": "Pakistan",
    "officialLanguageCodes": [
      "en",
      "ur"
    ]
  },
  "PAN": {
    "countryName": "Panama",
    "officialLanguageCodes": [
      "es"
    ]
  },
  "PCN": {
    "countryName": "Pitcairn",
    "officialLanguageCodes": [
      "en",
      "pih"
    ]
  },
  "PER": {
    "countryName": "Peru",
    "officialLanguageCodes": [
      "es"
    ]
  },
  "PHL": {
    "countryName": "Philippines",
    "officialLanguageCodes": [
      "en",
      "tl"
    ]
  },
  "PLW": {
    "countryName": "Palau",
    "officialLanguageCodes": [
      "en",
      "pau",
      "ja",
      "sov",
      "tox"
    ]
  },
  "PNG": {
    "countryName": "Papua New Guinea",
    "officialLanguageCodes": [
      "en",
      "tpi",
      "ho"
    ]
  },
  "POL": {
    "countryName": "Poland",
    "officialLanguageCodes": [
      "pl"
    ]
  },
  "PRI": {
    "countryName": "Puerto Rico",
    "officialLanguageCodes": [
      "es",
      "en"
    ]
  },
  "PRK": {
    "countryName": "North Korea",
    "officialLanguageCodes": [
      "ko"
    ]
  },
  "PRT": {
    "countryName": "Portugal",
    "officialLanguageCodes": [
      "pt"
    ]
  },
  "PRY": {
    "countryName": "Paraguay",
    "officialLanguageCodes": [
      "es",
      "gn"
    ]
  },
  "PSE": {
    "countryName": "Palestine, State of",
    "officialLanguageCodes": [
      "ar",
      "he"
    ]
  },
  "PYF": {
    "countryName": "French Polynesia",
    "officialLanguageCodes": [
      "fr"
    ]
  },
  "QAT": {
    "countryName": "Qatar",
    "officialLanguageCodes": [
      "ar"
    ]
  },
  "REU": {
    "countryName": "Réunion",
    "officialLanguageCodes": [
      "fr"
    ]
  },
  "ROU": {
    "countryName": "Romania",
    "officialLanguageCodes": [
      "ro"
    ]
  },
  "RUS": {
    "countryName": "Russia",
    "officialLanguageCodes": [
      "ru"
    ]
  },
  "RWA": {
    "countryName": "Rwanda",
    "officialLanguageCodes": [
      "rw",
      "fr",
      "en"
    ]
  },
  "SAU": {
    "countryName": "Saudi Arabia",
    "officialLanguageCodes": [
      "ar"
    ]
  },
  "SDN": {
    "countryName": "Sudan",
    "officialLanguageCodes": [
      "ar",
      "en"
    ]
  },
  "SEN": {
    "countryName": "Senegal",
    "officialLanguageCodes": [
      "fr"
    ]
  },
  "SGP": {
    "countryName": "Singapore",
    "officialLanguageCodes": [
      "zh-hans",
      "en",
      "ms",
      "ta"
    ]
  },
  "SGS": {
    "countryName": "South Georgia and the South Sandwich Islands",
    "officialLanguageCodes": [
      "en"
    ]
  },
  "SHN": {
    "countryName": "Saint Helena, Ascension and Tristan da Cunha",
    "officialLanguageCodes": [
      "en"
    ]
  },
  "SJM": {
    "countryName": "Svalbard and Jan Mayen",
    "officialLanguageCodes": [
      "no"
    ]
  },
  "SLB": {
    "countryName": "Solomon Islands",
    "officialLanguageCodes": [
      "en"
    ]
  },
  "SLE": {
    "countryName": "Sierra Leone",
    "officialLanguageCodes": [
      "en"
    ]
  },
  "SLV": {
    "countryName": "El Salvador",
    "officialLanguageCodes": [
      "es"
    ]
  },
  "SMR": {
    "countryName": "San Marino",
    "officialLanguageCodes": [
      "it"
    ]
  },
  "SOM": {
    "countryName": "Somalia",
    "officialLanguageCodes": [
      "so",
      "ar"
    ]
  },
  "SPM": {
    "countryName": "Saint Pierre and Miquelon",
    "officialLanguageCodes": [
      "fr"
    ]
  },
  "SRB": {
    "countryName": "Serbia",
    "officialLanguageCodes": [
      "sr",
      "sr-Latn"
    ]
  },
  "SSD": {
    "countryName": "South Sudan",
    "officialLanguageCodes": [
      "en"
    ]
  },
  "STP": {
    "countryName": "Sao Tome and Principe",
    "officialLanguageCodes": [
      "pt"
    ]
  },
  "SUR": {
    "countryName": "Suriname",
    "officialLanguageCodes": [
      "nl"
    ]
  },
  "SVK": {
    "countryName": "Slovakia",
    "officialLanguageCodes": [
      "sk"
    ]
  },
  "SVN": {
    "countryName": "Slovenia",
    "officialLanguageCodes": [
      "sl"
    ]
  },
  "SWE": {
    "countryName": "Sweden",
    "officialLanguageCodes": [
      "sv"
    ]
  },
  "SWZ": {
    "countryName": "Swaziland",
    "officialLanguageCodes": [
      "en",
      "ss"
    ]
  },
  "SXM": {
    "countryName": "Sint Maarten (Dutch part)",
    "officialLanguageCodes": [
      "nl",
      "en"
    ]
  },
  "SYC": {
    "countryName": "Seychelles",
    "officialLanguageCodes": [
      "fr",
      "en",
      "crs"
    ]
  },
  "SYR": {
    "countryName": "Syria",
    "officialLanguageCodes": [
      "ar"
    ]
  },
  "TCA": {
    "countryName": "Turks and Caicos Islands",
    "officialLanguageCodes": [
      "en"
    ]
  },
  "TCD": {
    "countryName": "Chad",
    "officialLanguageCodes": [
      "fr",
      "ar"
    ]
  },
  "TGO": {
    "countryName": "Togo",
    "officialLanguageCodes": [
      "fr"
    ]
  },
  "THA": {
    "countryName": "Thailand",
    "officialLanguageCodes": [
      "th"
    ]
  },
  "TJK": {
    "countryName": "Tajikistan",
    "officialLanguageCodes": [
      "tg",
      "ru"
    ]
  },
  "TKL": {
    "countryName": "Tokelau",
    "officialLanguageCodes": [
      "tkl",
      "en",
      "sm"
    ]
  },
  "TKM": {
    "countryName": "Turkmenistan",
    "officialLanguageCodes": [
      "tk"
    ]
  },
  "TLS": {
    "countryName": "Timor-Leste",
    "officialLanguageCodes": [
      "pt",
      "tet"
    ]
  },
  "TON": {
    "countryName": "Tonga",
    "officialLanguageCodes": [
      "en"
    ]
  },
  "TTO": {
    "countryName": "Trinidad and Tobago",
    "officialLanguageCodes": [
      "en"
    ]
  },
  "TUN": {
    "countryName": "Tunisia",
    "officialLanguageCodes": [
      "ar",
      "fr"
    ]
  },
  "TUR": {
    "countryName": "Turkey",
    "officialLanguageCodes": [
      "tr"
    ]
  },
  "TUV": {
    "countryName": "Tuvalu",
    "officialLanguageCodes": [
      "en"
    ]
  },
  "TWN": {
    "countryName": "Taiwan",
    "officialLanguageCodes": [
      "zh-hant"
    ]
  },
  "TZA": {
    "countryName": "Tanzania",
    "officialLanguageCodes": [
      "sw",
      "en"
    ]
  },
  "UGA": {
    "countryName": "Uganda",
    "officialLanguageCodes": [
      "en",
      "sw"
    ]
  },
  "UKR": {
    "countryName": "Ukraine",
    "officialLanguageCodes": [
      "uk"
    ]
  },
  "UMI": {
    "countryName": "United States Minor Outlying Islands",
    "officialLanguageCodes": [
      "en"
    ]
  },
  "URY": {
    "countryName": "Uruguay",
    "officialLanguageCodes": [
      "es"
    ]
  },
  "USA": {
    "countryName": "United States",
    "officialLanguageCodes": [
      "en"
    ]
  },
  "UZB": {
    "countryName": "Uzbekistan",
    "officialLanguageCodes": [
      "uz",
      "kaa"
    ]
  },
  "VAT": {
    "countryName": "Holy See (Vatican City State)",
    "officialLanguageCodes": [
      "it"
    ]
  },
  "VCT": {
    "countryName": "Saint Vincent and the Grenadines",
    "officialLanguageCodes": [
      "en"
    ]
  },
  "VEN": {
    "countryName": "Venezuela",
    "officialLanguageCodes": [
      "es"
    ]
  },
  "VGB": {
    "countryName": "British Virgin Islands",
    "officialLanguageCodes": [
      "en"
    ]
  },
  "VIR": {
    "countryName": "U.S. Virgin Islands",
    "officialLanguageCodes": [
      "en"
    ]
  },
  "VNM": {
    "countryName": "Vietnam",
    "officialLanguageCodes": [
      "vi"
    ]
  },
  "VUT": {
    "countryName": "Vanuatu",
    "officialLanguageCodes": [
      "bi",
      "en",
      "fr"
    ]
  },
  "WLF": {
    "countryName": "Wallis and Futuna",
    "officialLanguageCodes": [
      "fr"
    ]
  },
  "WSM": {
    "countryName": "Samoa",
    "officialLanguageCodes": [
      "sm",
      "en"
    ]
  },
  "YEM": {
    "countryName": "Yemen",
    "officialLanguageCodes": [
      "ar"
    ]
  },
  "ZAF": {
    "countryName": "South Africa",
    "officialLanguageCodes": [
      "en",
      "af",
      "st",
      "tn",
      "xh",
      "zu"
    ]
  },
  "ZMB": {
    "countryName": "Zambia",
    "officialLanguageCodes": [
      "en"
    ]
  },
  "ZWE": {
    "countryName": "Zimbabwe",
    "officialLanguageCodes": [
      "en",
      "sn",
      "nd"
    ]
  }
}
